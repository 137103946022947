<script lang="ts" setup>
import { ref, computed, watch } from 'vue';
import { GSyncProgress } from '@gem/uikit';
import { useInfiniteScroll } from '@vueuse/core';
import { replaceImageToSize } from '../../hook/useResizeImage';
import GCheckboxCustom from './GCheckboxCustom.vue';
import { GAlert } from '@gem/uikit';
import ProductItemVariantSelect from './ProductItemVariantSelect.vue';
import { useProductVariantSelected } from './hooks/useProductVariantSelected';
import type { PreVariantSelected, Product } from './types';

// props & emit
type Props = {
  value?: Product[];
  list?: Product[];
  isLoading?: boolean;
  open?: boolean;
  type: 'Product';
  max?: number;
  syncPercent?: number;
  pageType?: string;
  warningMessage?: string;
  inputSearchComponent?: Object;
  maxAllowSelected?: number;
  variantSelected?: any;
  isPostPurchase?: boolean;
};

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'showMore'): void;
  (e: 'refresh'): void;
  (e: 'setValueSelected', value?: Product[]): void;
  (e: 'onSearch', value?: string): void;
  (e: 'setPreVariantSelected', value?: PreVariantSelected): void;
}>();
//================  const =================
const productInfinityScroll = ref<HTMLElement | null>(null);
const searchKeyword = ref<string | undefined>();
const listProduct = ref<Product[]>(props.value ?? []);
const productListNotFound = computed(() => props.list?.length == 0);
const isInstant = computed(() => props.pageType === 'STATIC');
const {
  productVariantSelect,
  handleDeleteVariant,
  handleAddVariant,
  handleChangeVariant,
  handleRemoveVariantDefault,
  handleSelectVariantDefault,
} = useProductVariantSelected({
  value: props.variantSelected,
  list: props.list,
  productsSelected: listProduct.value,
});

//================ methods =================

useInfiniteScroll(
  productInfinityScroll,
  () => {
    emit('showMore');
  },
  { distance: 100 },
);
// const onSearch = (value?: string) => {
//   searchKeyword.value = value;
//   emit('onSearch', searchKeyword.value);
// };

const onSearch = () => {
  emit('onSearch', searchKeyword.value);
};

const setValueSelected = (item?: Product) => {
  if (item?.id) {
    const product = listProduct.value?.find((i) => i.id === item.id);
    if (!product) {
      if (props.maxAllowSelected && listProduct.value.length >= props.maxAllowSelected) {
        alert(`You can only select up to ${props.maxAllowSelected} products`);
        return;
      }
      if (listProduct.value.length >= 24) {
        alert('You can only select up to 24 products!');
        return;
      }
      listProduct.value?.push(item);
      handleAddVariant(item);
    } else {
      if (listProduct.value.length <= 1) {
        alert('You must choose at least 1 product!');
        return;
      }
      const index = listProduct.value.findIndex((i) => i.id === item.id);
      listProduct.value.splice(index, 1);
      handleDeleteVariant(item);
    }
  }
};

const save = () => {
  emit('setValueSelected', listProduct.value);
  if (props.isPostPurchase) emit('setPreVariantSelected', productVariantSelect);

  close();
};
const close = () => {
  clear();
  emit('close');
};
const clear = () => {
  searchKeyword.value = '';
  onSearch();
};

const toggleCheckbox = (item?: Product) => {
  setValueSelected(item);
};

const updateSearchInput = (value: string) => {
  searchKeyword.value = value;
  setTimeout(() => {
    emit('onSearch', searchKeyword.value);
  }, 200);
};

watch(
  () => props.value,
  (newValue) => {
    listProduct.value = newValue ?? [];
  },
);
</script>
<template>
  <div>
    <Teleport to="body">
      <g-modal
        :hide-actions="false"
        :is-open="open"
        :show-btn-close="true"
        :show-line-top="false"
        :is-draggable="true"
        body-class="bg-light-400 pb-16 max-h-[calc(90vh_-_116px)]"
        :btn-ok-disable="productListNotFound"
        header-class="cursor-default"
        modal-class="!w-[600px]"
        container-class="absolute !left-[337px] [@media(max-height:584px)]:max-h-full [@media(max-height:584px)]:bottom-16"
        @close="close"
        @ok="save"
        @cancel="close">
        <template #title>
          <div class="flex h-full w-full items-center">
            <div>
              <p>Select products</p>
            </div>
            <div class="flex h-full flex-1 items-center justify-end">
              <div
                class="group/button flex h-[52px] w-[52px] flex-shrink-0 cursor-pointer items-center justify-center"
                @click.stop="emit('refresh')">
                <GButtonV2
                  type="ghost"
                  only-icon="refresh-select"
                  icon-view-box="0 0 16 16"
                  icon-loading="small-loading"
                  :loading="isLoading"
                  :disable="isLoading"
                  :light-mode="true"
                  size="semi-medium"
                  data-test="editor-control-modal-button-refresh">
                </GButtonV2>
              </div>
              <div class="bg-light-400 absolute h-24 w-[1px]"></div>
            </div>
          </div>
        </template>
        <div>
          <div v-if="!!warningMessage" class="mt-16 px-16">
            <GAlert type="warning" :is-show-close="false" class="rounded-12">
              <div class="text-14" v-html="warningMessage" />
            </GAlert>
          </div>
          <div class="flex items-center">
            <div class="flex w-full items-center p-16">
              <component
                :is="inputSearchComponent"
                :query-value="searchKeyword"
                :is-loading="isLoading"
                placeholder="Search product..."
                data-test="setting-modal-multi-products-input-search"
                class="w-full"
                input-style="normalLight"
                @search="updateSearchInput"
                @clear="clear" />
            </div>
          </div>
          <div class="flex flex-col">
            <g-sync-progress :percent-sync-done="syncPercent" />
            <div
              v-if="!productListNotFound && isInstant"
              class="text-14 text-text-light-300 sticky top-0 flex px-16 pb-12 font-medium">
              <p>Instant Page only show “Active” products.</p>
            </div>

            <perfect-scrollbar ref="productInfinityScroll" class="!h-[53vh] px-16">
              <div>
                <div class="bg-light-400">
                  <div
                    class="bg-light-400 relative flex flex-1 flex-col gap-16"
                    data-test="setting-modal-multi-products-listing">
                    <div
                      v-for="(item, index) in list"
                      :key="index"
                      data-test="setting-modal-multi-products-item"
                      class="group/white flex cursor-pointer items-center rounded-[12px] bg-white">
                      <div
                        class="mb-auto flex h-[72px] w-[72px] flex-shrink-0 items-center justify-center rounded-l-[12px] bg-white p-8"
                        :class="{
                          'flex items-center justify-center': !item.image,
                        }">
                        <img
                          v-if="item.image"
                          class="h-[56px] w-[56px] rounded-[6px]"
                          :src="replaceImageToSize(item.image, '100x100') ?? item.image"
                          alt="product" />
                        <svg
                          v-else
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M14.6985 12.6684L18.4448 19.1091C18.653 19.4672 19.1121 19.5886 19.4702 19.3804C19.8282 19.1721 19.9497 18.713 19.7414 18.355L15.7924 11.5656C15.326 10.7637 14.1793 10.7314 13.6685 11.5058L11.1029 15.3953L9.58381 14.0553C9.01799 13.5562 8.14232 13.6684 7.72052 14.2939L5.01074 18.3128C4.77917 18.6562 4.86986 19.1223 5.21329 19.3539C5.55673 19.5855 6.02286 19.4948 6.25443 19.1513L8.80506 15.3686L10.7577 17.091C10.9206 17.2346 11.1377 17.3006 11.3529 17.2719C11.5681 17.2433 11.7604 17.1227 11.8799 16.9415L14.6985 12.6684Z"
                            fill="#676767" />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.50732 5.24986C6.05758 5.24986 4.88232 6.42512 4.88232 7.87486C4.88232 9.32461 6.05758 10.4999 7.50732 10.4999C8.95707 10.4999 10.1323 9.32461 10.1323 7.87486C10.1323 6.42512 8.95707 5.24986 7.50732 5.24986ZM6.38232 7.87486C6.38232 7.25354 6.886 6.74986 7.50732 6.74986C8.12864 6.74986 8.63232 7.25354 8.63232 7.87486C8.63232 8.49618 8.12864 8.99986 7.50732 8.99986C6.886 8.99986 6.38232 8.49618 6.38232 7.87486Z"
                            fill="#676767" />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.25 3C1.25 2.0335 2.0335 1.25 3 1.25H21C21.9665 1.25 22.75 2.0335 22.75 3V21C22.75 21.9665 21.9665 22.75 21 22.75H3C2.0335 22.75 1.25 21.9665 1.25 21V3ZM3 2.75C2.86193 2.75 2.75 2.86193 2.75 3V21C2.75 21.1381 2.86193 21.25 3 21.25H21C21.1381 21.25 21.25 21.1381 21.25 21V3C21.25 2.86193 21.1381 2.75 21 2.75H3Z"
                            fill="#676767" />
                        </svg>
                      </div>
                      <div
                        class="flex w-full items-center justify-around p-16"
                        @click="setValueSelected(item)"
                        @keypress="setValueSelected(item)">
                        <div>
                          <h4
                            v-if="!isPostPurchase"
                            class="text-14 line-clamp-1 text-text-light-500 mr-16 overflow-hidden text-ellipsis font-medium leading-6"
                            :class="{
                              'mr-[72px]': item.status?.toLowerCase() !== 'active',
                              '!mr-[90px]': item.status?.toLowerCase() === 'archived',
                            }">
                            {{ item?.title }}
                          </h4>
                          <ProductItemVariantSelect
                            v-else
                            :item="item"
                            :variants="productVariantSelect"
                            :list="list"
                            :products-selected="listProduct"
                            @handle-change-variant="handleChangeVariant"
                            @handle-remove-variant-default="handleRemoveVariantDefault"
                            @handle-select-variant-default="handleSelectVariantDefault" />
                        </div>

                        <GCheckboxCustom
                          :value="listProduct?.find((i) => i.id === item.id) ? true : false"
                          class="pointer-events-none relative top-[2px] -mt-10 ml-auto !w-auto" />
                      </div>
                      <div
                        v-if="item.status?.toLowerCase() !== 'active'"
                        class="text-12 text-text-light-300 bg-light-300 absolute right-[52px] rounded-xl p-[2px] px-8 font-medium capitalize leading-5"
                        :class="{
                          'bg-underlay-yellow-100 text-yellow-400': item.status?.toLowerCase() === 'archived',
                        }">
                        {{ item.status?.toLowerCase() }}
                      </div>
                    </div>
                    <div
                      v-if="isLoading"
                      class="border-light-450 flex items-center border-x border-b"
                      :class="{ 'border-y': list?.length !== 0, 'border-b': list?.length === 0 }">
                      <GLoadingPlaceholder width="100%" height="58px"></GLoadingPlaceholder>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="productListNotFound" class="flex h-[calc(100%_-_10px)] flex-col items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M20.4826 61.894C29.0334 61.894 35.9652 54.9622 35.9652 46.4113C35.9652 37.8605 29.0334 30.9287 20.4826 30.9287C11.9318 30.9287 5 37.8605 5 46.4113C5 54.9622 11.9318 61.894 20.4826 61.894ZM20.4826 64.894C30.6903 64.894 38.9652 56.619 38.9652 46.4113C38.9652 36.2037 30.6903 27.9287 20.4826 27.9287C10.275 27.9287 2 36.2037 2 46.4113C2 56.619 10.275 64.894 20.4826 64.894Z"
                    fill="#9E9E9E" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M43.5869 71.6359L30.8672 58.9162L32.9885 56.7949L45.7082 69.5146L43.5869 71.6359Z"
                    fill="#9E9E9E" />
                  <path
                    d="M37.8904 20C37.91 23.3133 35.2402 26.0159 31.9258 26.0355C35.2402 26.0159 37.942 28.6865 37.9615 32C37.942 28.6865 40.6126 25.9841 43.9258 25.9644C40.6126 25.9841 37.91 23.3133 37.8904 20Z"
                    fill="#9E9E9E" />
                  <path
                    d="M10.4794 66C10.4908 67.9328 8.9334 69.5093 7 69.5207C8.9334 69.5093 10.5095 71.0671 10.5208 73C10.5095 71.0671 12.0673 69.4907 14 69.4792C12.0673 69.4907 10.4908 67.9328 10.4794 66Z"
                    fill="#9E9E9E" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18 53H75V11L18 11L18 53ZM18 8C16.3431 8 15 9.34315 15 11V53C15 54.6569 16.3431 56 18 56H75C76.6569 56 78 54.6569 78 53V11C78 9.34315 76.6569 8 75 8H18Z"
                    fill="#9E9E9E" />
                  <path
                    d="M60.2374 17C62.4286 17 64.1992 17.6197 65.5493 18.8592C66.8994 20.0986 67.5745 21.7586 67.5745 23.8391C67.5745 26.163 66.8552 27.8894 65.4165 29.0181C63.9779 30.1248 62.0302 30.6781 59.5735 30.6781L59.4739 33.832H56.8511L56.7183 28.5866H57.6811C59.8723 28.5866 61.5876 28.2546 62.827 27.5906C64.0664 26.9266 64.6862 25.6761 64.6862 23.8391C64.6862 22.5111 64.2878 21.4598 63.491 20.6851C62.6942 19.9105 61.6208 19.5231 60.2706 19.5231C58.8984 19.5231 57.8139 19.8994 57.0171 20.6519C56.2425 21.3823 55.8551 22.3894 55.8551 23.6731H53C53 22.3451 53.2988 21.1831 53.8964 20.1871C54.494 19.169 55.335 18.3833 56.4195 17.83C57.5262 17.2767 58.7988 17 60.2374 17ZM58.1459 40.6379C57.5704 40.6379 57.0835 40.4387 56.6851 40.0403C56.2867 39.6419 56.0875 39.155 56.0875 38.5795C56.0875 38.0041 56.2867 37.5172 56.6851 37.1188C57.0835 36.7204 57.5704 36.5212 58.1459 36.5212C58.6992 36.5212 59.164 36.7204 59.5403 37.1188C59.9387 37.5172 60.1378 38.0041 60.1378 38.5795C60.1378 39.155 59.9387 39.6419 59.5403 40.0403C59.164 40.4387 58.6992 40.6379 58.1459 40.6379Z"
                    fill="#9E9E9E" />
                </svg>
                <p class="text-14 font-regular text-text-light-300">No products match your search.</p>
                <p class="text-14 font-regular text-text-light-300">Please try another keyword</p>
              </div>
            </perfect-scrollbar>
          </div>
        </div>
      </g-modal>
    </Teleport>
  </div>
</template>
